import { useRef, useState, useEffect, createContext, useContext } from 'react';

import Header from './Header/Header';
import CookieConsent from './CookieConsent';
import NewsCard from './NewsCard';
import Address from './Address';
import LeasingCard from './LeasingCard';
import ServiceFinanzierung from './ServiceFinanzierung';
import BackgroundImage from './BackgroundImage';
import EbikeCard from './EbikeCard';
import ServiceWerkstatt from './ServiceWerkstatt';
import ContactMaps from './ContactMaps';
import FormCard from './FormCard';
import NewsUpload from './NewsUpload';
import Footer from './Footer/Footer';
import Products from './Products';

import { PageContext } from '../App';
import { BannerContext } from './MainPage';

import Background1 from "../images/background/hermann1.jpg";
import Background2 from "../images/background/morgenstern2.jpg";
import Background3 from "../images/background/fahrrad1.jpg";
import Background4 from "../images/background/schlaeuche.jpg";
import Background5 from "../images/background/hermann3.jpg";


const MobileMain = () => {

  const {page, setPage} = useContext(PageContext);
  const {showBanner, setShowBanner} = useContext(BannerContext);

  let cardOffset = 0;

  const showCookieBanner = () =>{
    let banner = document.getElementById("cookie-banner")
    banner.style.display = "Block"
  }

  const initialScroll = () => {

    let anchor = "";
    if(page.trim() == "Service"){
        anchor = document.getElementById('service');
    }
    else if(page === "Produkte"){
        anchor = document.getElementById('produkte');
    }
    else if(page === "Kontakt"){
        anchor = document.getElementById('kontakt');
    }

    if(anchor != ""){
        anchor.scrollIntoView();
    }
    setPage("");
  }

  useEffect(() => {
    initialScroll();
  })

    return (
        <div className={'body-wrapper'}>
                
                <Header></Header>
                
                <div className={'first-background'}>
                    <BackgroundImage Path={Background1} ></BackgroundImage>
                </div>
                <NewsCard></NewsCard>
                <Address></Address>

                <div id='service'>
                    <BackgroundImage Path={Background2} ></BackgroundImage>
                </div>
                <LeasingCard></LeasingCard>
                <ServiceFinanzierung></ServiceFinanzierung>
                
                <BackgroundImage Path={Background4} ></BackgroundImage>
                <EbikeCard></EbikeCard>
                <ServiceWerkstatt></ServiceWerkstatt>          

                <div id="produkte">
                    <BackgroundImage Path={Background3} ></BackgroundImage>
                </div>
                <Products></Products>

                <div id='kontakt'>
                    <BackgroundImage Path={Background5} ></BackgroundImage>
                </div>
                <FormCard></FormCard>
                <ContactMaps showCookieBanner={showCookieBanner}></ContactMaps>

                <Footer></Footer>

                {showBanner && (
                    <CookieConsent></CookieConsent>
                )}
        </div>
    );
}

export default MobileMain