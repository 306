import "./../Stylesheets/Main.css"
import { useEffect, useState } from "react";


import giant from "./../images/products/giant.png";
import batavus from "./../images/products/batavus.png";
import greens from "./../images/products/greens.png";
import hercules from "./../images/products/hercules.png";

import bbf from "./../images/products/bbf.png";
import panther from "./../images/products/panther.jpg";
import puky from "./../images/products/puky.gif";
import bottecchia from "./../images/products/bottecchia.png";

import cp from "./../images/products/cp.png";
import maxcycles from "./../images/products/maxcycles.png";
import viva from "./../images/products/viva.png";
import dahon from "./../images/products/dahon.png";

import winora from "./../images/products/winora.png";


const Products = () => {

    return (
        <div className="product-wrapper background-text" id="product-panel">
            <h1 className="anfahrt-title">
                Unsere Fahrradmarken
            </h1>
            <div className="product-gallery">
                <div className="product-column">
                    <div className="product-row">
                        <img src={giant} alt="Giant" className="product-logo" />  
                    </div>
                    <div className="expandable">
                        <div className="product-row">
                            <img src={bbf} alt="BBF" className="product-logo" />  
                        </div>
                        <div className="product-row">
                            <img src={cp} alt="Checker Pig" className="product-logo" />  
                        </div>
                        <div className="product-row">
                            <img src={winora} alt="Winora" className="product-logo" />  
                        </div>
                    </div>
                </div>
                <div className="product-column">
                    <div className="product-row">
                        <img src={greens} alt="Greens" className="product-logo" />
                    </div>
                    <div className="expandable">
                        <div className="product-row">
                            <img src={panther} alt="Panther" className="product-logo" />
                        </div>
                        <div className="product-row">
                            <img src={maxcycles} alt="MaxCycles" className="product-logo" />
                        </div>
                    </div>
                    
                </div>
                <div className="product-column">
                    <div className="product-row">
                        <img src={hercules} alt="Hercules" className="product-logo" /> 
                    </div>
                    <div className="expandable">
                        <div className="product-row">
                            <img src={bottecchia} alt="Bottecchia" className="product-logo" /> 
                        </div>
                        <div className="product-row">
                            <img src={dahon} alt="Dahon" className="product-logo" /> 
                        </div>
                    </div>
                </div>
                <div className="product-column">
                    <div className="product-row">
                        <img src={batavus} alt="Batavus" className="product-logo" /> 
                    </div>

                    <div className="expandable">
                        <div className="product-row">
                            <img src={puky} alt="Puky" className="product-logo" /> 
                        </div>
                        <div className="product-row">
                            <img src={viva} alt="Viva" className="product-logo" /> 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Products;