
import './Stylesheets/Main.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


import { useRef, useState, useEffect, createContext } from 'react';
import MainPage from './Components/MainPage';
import NewsPage from './Components/NewsPage';
import Datenschutzpage from './Components/DatenschutzPage';
import AdminLogin from './Components/AdminLogin';

export const PageContext = createContext({});

function App() {

  const [page, setPage] = useState("");

    return(
      <PageContext.Provider value={{ page: page, setPage: setPage }} >
        <Router>
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route
                    path="/news"
                    element={<NewsPage />}
                />
          <Route
                    path="/datenschutz"
                    element={<Datenschutzpage />}
                />
          
        </Routes>
      </Router> 
      </PageContext.Provider>
      );
  }

export default App;
